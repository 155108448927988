.footer{
    border-top: .2rem solid #b2a37d;
    max-width: 1920px;
    margin: 0 auto;
    background: url('../../images/Footer-bg-v1.1.jpg') no-repeat !important;
    background-size: cover;
    background-position: center;
    padding: 0 2rem;
}
.footer-container{
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center ;
    background: url('../../images/Footer-bg-v1.1.jpg') !important;
    background-size: cover;
    background-position: center;
    color: #fff;
}
.footer-container img{
    padding: 0 0 3rem;
}

.footer-container p{
    font-size: calc(2rem + (32 - 32) * ((100vw - 600px) / (1920 - 600)));
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 3rem;
    color: #0FD9FF;
    padding-bottom: 3rem;
    margin-bottom: 0;
}

.footer_inner{
    display: flex;
    padding-top: 3rem;
    width: 100%;
    max-width: 1800px;
}
.footer_column{
    width: 20.875%;
    margin-right: 5.5%;
    padding-bottom: 2rem;
}

.et_pb_row{
    width: 100%;
}

.et_pb_row_0_tb_footer.et_pb_row{
    padding-top: 3rem!important;
    padding-bottom: 0px!important;
}

.et_pb_row_1_tb_footer{
    max-width: 1800px;
}

.et_pb_section_1_tb_footer.et_pb_section{
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #000;
}

.et_pb_row_2_tb_footer{
    padding: 27px 0;
    background-color: #000!important;
}

.et_pb_section_1_tb_footer{
    border-top-width: 0.2rem;
    border-top-color: #3d3d3d;
}

.et_pb_gutters3 .et_pb_column_1_4 .et_pb_module, .et_pb_gutters3.et_pb_row .et_pb_column_1_4 .et_pb_module{
    margin-bottom: 1.5rem !important;
}

.et_pb_text_0_tb_footer, .et_pb_text_1_tb_footer,.et_pb_text_2_tb_footer,.et_pb_text_3_tb_footer,.et_pb_text_4_tb_footer
.et_pb_text_5_tb_footer,.et_pb_text_6_tb_footer,.et_pb_text_7_tb_footer{
    margin-bottom: 0;
}

.et_pb_text_inner p{
    margin-bottom: 0;
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    font-weight: normal;
    font-size: 1.063rem;
    letter-spacing: 0em;
}

.et_pb_text_0_tb_footer{
    font-family: 'Oswald',Helvetica,Arial,Lucida,sans-serif;
    font-size: 2rem;
    letter-spacing: 0.06rem;
    line-height: 2.5rem;
    padding-bottom: 0px!important;
    width: 100%;
}

.et_pb_text_1_tb_footer{
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    font-size: 1.25rem;
    letter-spacing: 0.06rem;
    line-height: 1.38rem;
}

.et_pb_text_2_tb_footer.et_pb_text{
    color: #b2a37d;
}

.et_pb_text_2_tb_footer{
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    font-size: 1.25rem;
    letter-spacing: 0.06rem;
    line-height: 1.94rem;
}

.et_pb_text_3_tb_footer.et_pb_text{
    color: #fff;
}
.et_pb_text_3_tb_footer{
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
}

.et_pb_text_4_tb_footer.et_pb_text{
    color: #b2a37d!important;
}

.et_pb_text_4_tb_footer{
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    letter-spacing: 0.06rem;
    line-height: 1.94rem;
}

.et_pb_text_5_tb_footer.et_pb_text{
    color: #fff;
}

.et_pb_text_5_tb_footer{
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
}


.et_pb_text_12_tb_footer{
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    font-size: 1.25rem;
    letter-spacing: 0.06rem;
    line-height: 1.94rem;
    padding-left: 0.5rem!important;
    margin-bottom: 1rem!important;
}

.et_pb_text_13_tb_footer{
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    font-size: 1.25rem;
    letter-spacing: 0.06rem;
    line-height: 1.94rem;
    padding-left: 0.5rem!important;
    margin-bottom: 1rem!important;
}

.et_pb_social_media_follow li{
    display: inline-block;
    margin-bottom: 8px;
    position: relative;
    color: #000;
    font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
    font-weight: normal;
    font-size: 1.063rem;
    letter-spacing: 0em;
}

.et_pb_social_media_follow li a.icon:before{
    color: #b2a37d;
    font-size: 1.2rem;
    line-height: 2.2rem;
    height: 2.2rem;
    width: 2.2rem;
}

.et_pb_social_media_follow .et_pb_social_media_follow_network_0_tb_footer .icon{
    height: 2.2rem;
    width: 2.2rem;
}