.sectionFive-container{
    padding: 0 0 4rem;
    max-width: 1920px;
    margin: 0 auto;
    background: #fff;
    max-width: 1920px;
    margin: 0 auto;
    background: #09abc4;
    color: #fff;
}


.sectionFive-container > .sectionFive-container-inner > div{
    display: flex;
    justify-content: center;
    padding: 2rem 0;
}

.sectionFive-container > .sectionFive-container-inner > div > div{
    padding: 0 1rem;
    width: 40%
}

.sectionFive-container > .sectionFive-container-inner > div > div ul{
    text-align: left;
}

.sectionFive-container > .sectionFive-container-inner > div > div ul li{
    font-size: calc(1rem + (18 - 16) * ((100vw - 600px) / (1920 - 600)));
}

.sectionFive-container > .sectionFive-container-inner h5{
    color: #fff;
    font-size: calc(2rem + (43 - 32) * ((100vw - 600px) / (1920 - 600)));
    font-weight: bold;
    padding: 0 4rem 1rem;
    letter-spacing: 2px;
}

.sectionFive-container > .sectionFive-container-inner p{
    color: #fff;
    width: 65%;
    margin: auto;
    /* font-size: calc(1.125rem + (16 - 16) * ((100vw - 320px) / (1600 - 300))); */
    letter-spacing: 0.02rem;
    /* line-height: 2.0635rem; */
    padding: 1rem 0;
}

.sectionFive-container-inner button{
    background: #AA1F9F;
    color: #fff;
    margin: auto;
    font-weight: bold;
    font-size: 1.5rem !important;
    letter-spacing: 0.02rem;
    line-height: 1.875rem;
    padding: 1.2% 2%;
    border-radius: .5rem;
    max-width: 600px;
    display: inline;
    outline: none;
    margin: 2rem 0;
}

.sectionFive-container-inner button:hover{
    background: #09E9FF !important;
    color: #02043d !important;
    transform: scale(1);
}

button.modal-video-close-btn{
    background: none;
    right: -3rem;
}

button.modal-video-close-btn:hover{
    background: none !important;
}

.sectionFive-container > .sectionFive-container-inner .package-container {
    display: flex;
    justify-content: space-between;
}

.sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner img{
    width: 90%;
}

.sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner .one, .two, .three ,.four{
    position: relative;
}

.sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner .play{
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    width: 4.8125rem;
    cursor: pointer;
  } 


.sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner p{
    padding: 1.5rem 0 0;
    font-size: 1.7375rem;
    letter-spacing: 0.09rem;
    line-height: 1.15;
    font-weight: bold;
    padding-bottom: 1rem;
}

.sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner span{
    font-size: calc(1rem + (18 - 16) * ((100vw - 600px) / (1920 - 600)));
}

@media screen and (min-width: 1920px){

    .sectionFive-container > .sectionFive-container-inner h5{
        font-size: 	2.6875rem;
    }
    
    .sectionFive-container > .sectionFive-container-inner p{
        font-size: 1.063rem;
    }

    .sectionFive-container > .sectionFive-container-inner > div > div ul li{
        font-size: 1.125rem;
    }
}


@media screen and (max-width: 992px){
    .sectionFive-container > .sectionFive-container-inner h5{
        padding: 0 2rem;
    }
    .sectionFive-container > .sectionFive-container-inner .package-container{
        flex-wrap: wrap;
    }

    .sectionFive-container > .sectionFive-container-inner > div > div{
        width: 100%;
    }
    .sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner{
        padding: 2rem;
        width: 100%;
    }

    .sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner .play{
        width: 77px;
    }
}

@media screen and (max-width: 768px){
    .sectionFive-container > .sectionFive-container-inner h5,.sectionFive-container > .sectionFive-container-inner p{
        padding: 1rem 2rem;
    }
    
    .sectionFive-container > .sectionFive-container-inner > div{
        flex-direction: column;
        padding: 2rem;
    }

    .sectionFive-container > .sectionFive-container-inner > div > div ul li{
        text-align: center;

    }

    .sectionFive-container > .sectionFive-container-inner .package-container{
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (max-width: 600px){
    .sectionFive-container{
       padding: 2rem 2rem;
    }

    .sectionFive-container > .sectionFive-container-inner h5{
        font-size: 1.6rem;
        font-weight: bold;
        padding: 1rem 2rem;
        line-height: 1.5;
    }

    .sectionFive-container > .sectionFive-container-inner p{
        line-height: 1.2;
        padding: 0 2rem;
    }

    .sectionFive-container > .sectionFive-container-inner > div{
        padding: 0 2rem;
    }

    .sectionFive-container > .sectionFive-container-inner > div > div{
        padding-bottom: 0rem;
    }

    .sectionFive-container > .sectionFive-container-inner > div > div ul li{
        text-align: left;
    }

    
    .sectionFive-container > .sectionFive-container-inner > div > div ul{
        padding: 0;
    }

    .sectionFive-container > .sectionFive-container-inner .package-container, .sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner{
        padding: 2rem 0;
    }

    .sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner img{
        width: 100%;
    }

    .play{
        width: 130px !important;
    }

    .sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner div img:nth-child(1){
        box-shadow:  0 10px 10px #DCDCDC, 0 -10px 10px #DCDCDC;
    }

    .sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner p{
        /* padding: 3rem 2rem 1rem;
        margin-bottom: 0;
        font-size: 1.125rem; */
    }

    .sectionFive-container > .sectionFive-container-inner .package-container .package-container-inner span{
        display: block;
        padding: 0 2rem;
    }

    .sectionFive-container > .sectionFive-container-inner h3{
        text-align: left;
    }
    .sectionFive-container > .sectionFive-container-inner p{
        text-align: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .play{
        max-width: 75px;
    }
}