.testimonial_container{
    background: #fff;
    padding: 4rem 2rem;
    max-width: 1920px;
    margin: auto;
}

.testimonial_inner{
    width: 85%;
    margin: auto;
}

.testimonial_inner h5{
    color:#0FD9FF;
    text-align: center;;
    margin: auto;
    font-size: 2.6rem;
}

.testimonial_inner p{
    padding: 2rem 0;
}
.testimonial_features{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 2rem 0;
    flex-wrap: wrap;
}

.testimonial_features_left, .testimonial_features_right{
    display: flex;
    flex-direction: column;
}

.testimonial_features_left{
    width: 50%;
    margin-right: auto;
    align-items: flex-start;
}

.testimonial_features_right{
    align-items: flex-start;
}

.testimonial_content{
    padding: .5rem 0;
}

.testimonial_content img{
    padding-right: 1rem;
}

@media screen and (max-width: 992px){
    .testimonial_inner p, .testimonial_inner h5{
        text-align: left;
    }
    .testimonial_features_left, .testimonial_features_right{
        width: 100%;
    }
}

@media screen and (max-width: 768px){
    .testimonial_inner{
        width: 100%;;
    }

    .testimonial_features{
        justify-content: center;
    }
}

@media screen and (max-width: 600px){
    .testimonial_inner p, .testimonial_inner h5{
        text-align: center;
    }

    .testimonial_content{
        display: flex;
        justify-content: space-between;
    }

    .testimonial_content img{
        max-height: 24px;
    }

    .testimonial_content span{
        text-align: left;
    }

}