@font-face {
    font-family: 'si';
    src: url("../../font/socicon.eot");
    src: url("../../font/socicon.eot") format("embedded-opentype"),
         url("../../font/socicon.woff") format("woff"),
         url("../../font/socicon.ttf") format("truetype"),
         url("../../font/socicon.svg") format("svg");
    font-weight: normal;
    font-style: normal;

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family:si;
        src: url("../../font/socicon.svg") format(svg);
    }
}

.soc {
    overflow:hidden;
    margin:0; padding:0;
    list-style:none;
}

.soc li {
    display:inline-block;
    *display:inline;
    zoom:1;
}

.soc li a {
    font-family:si!important;
    font-style:normal;
    font-weight:400;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -ms-box-sizing:border-box;
    -o-box-sizing:border-box;
    box-sizing:border-box;
    overflow:hidden;
    text-decoration:none;
    text-align:center;
    display:block;
    position: relative;
    z-index: 1;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 18px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-right: 1rem;
    color: #ffffff;
    background-color: none;
}

.soc-icon-last{
    margin:0 !important;
}

.soc-twitter {
    background-color: #fff;
}
.soc-twitter:before {
    content:"\e040";
    color: #242429;
}
.soc-facebook {
    background-color: #fff;
}
.soc-facebook:before {
    content:"\e041";
    color: #242429;
}
.soc-instagram{
    background: #fff;
    color: #242429;
}
.soc-instagram:before {
    content:'\e057';
    color: #242429;
}
