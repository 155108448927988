.sup_small{
    vertical-align: text-top;
}

.sectionSix-container{
    background: url('../../images/Social-BG-v1.jpg') #fff;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding: 4rem 2rem;
    max-width: 1920px;
    margin: 0 auto;
}


.sectionSix-container  .sectionSix-container-inner{
    width: 75%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.sectionSix-container > .sectionSix-container-inner > button{
    background: #AA1F9F;
    color: #fff;
    margin: auto;
    font-weight: bold;
    font-size: 1.5rem !important;
    letter-spacing: .2rem;
    line-height:  1.875rem;
    padding: 2% 3%;
    border-radius: .5rem;
    max-width: 600px;
    display: inline;
    outline: none;
}

.sectionSix-container > .sectionSix-container-inner > button:hover{
    background: #09E9FF !important;
    color: #02043d !important;
    transform: scale(1);
}

.sectionSix-container  .sectionSix-container-inner .title{
    font-size:  2.5rem;
    font-size: calc(2rem + (40 - 32) *((100vw - 600px) / (1920 - 600)));
    line-height: 3rem;
    letter-spacing: 0.06rem;
    font-weight: bold;
    padding: 0 0 2rem;
}


.sectionSix-container  .sectionSix-container-inner .follow{
    padding: 3rem 0 2rem;
    font-weight: bold;
}
.sectionSix-container  .sectionSix-container-inner .social-container{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: auto;
    flex-wrap: wrap;
}

.sectionSix-container  .sectionSix-container-inner .social-container-inner{
    display: flex;
    flex-direction: column;
}

.sectionSix-container  .sectionSix-container-inner .social-container-inner {
    padding-right: 4rem;
}

.padRight{
    padding-right: 2rem;
}

@media screen and (min-width: 1920px){
    .sectionSix-container  .sectionSix-container-inner .title{
        font-size: 	2.5rem
    }
    
    .sectionSix-container  .sectionSix-container-inner p{
        font-size: 1.3125rem
    }

    .sectionSix-container > .sectionSix-container-inner > button{
        font-size: 1.875rem;
    }
}

@media screen and (max-width: 992px){
    .sectionSix-container .sectionSix-container-inner .social-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .sectionSix-container .sectionSix-container-inner .social-container-inner{
        padding-right: 0;
    }
}

@media screen and (max-width: 600px){
    .sectionSix-container{
        padding: 4rem 2rem 4rem;
    }
    .sectionSix-container  .sectionSix-container-inner .title{
        font-size: 2rem;
    }

    .sectionSix-container > .sectionSix-container-inner > button{
        font-size: 1rem !important;
        padding: 3%;
    }
}