.hero{
    height: 105vh;
    max-height: 105vh;
    min-height: 900px;
    background: #000;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    padding: 2rem 0;
    background: #000 url("../../images/Baron-Esports Championship Rings-COD-Halo Rings.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 1920px;
    margin: 0 auto;
}

.hero img{
    width: 125px;
}


.hero .infobox-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 3.5rem 25% 0 0;
    
}


.hero .infobox-container .infobox-left{
    display: flex;
    flex-direction: column;
    text-align: left;
    /* min-width: 650px; */
    position: relative;
    width: 650px;
    padding-left: 1.5rem;
}

.hero .infobox-container .infobox-left h1{
    font-family: 'oswald', sans-serif;
    font-size: 3.6875rem;
    font-size: calc(3rem + (59 - 48) *((100vw - 600px) / (1920 - 600)));
    letter-spacing:  0.06rem;
    margin-bottom: 2rem ;
    font-weight: 400;
    color: #0FD9FF;
    padding-bottom: 0;
}

.hero .infobox-container .infobox-left p{
    margin: .9rem 0;
    padding: 1rem 0;
    font-size: calc(1rem + (18 - 16) * ((100vw - 600px) / (1920 - 600)));
}


.hero .infobox-container .infobox-right{
    width: 40%;
}

.button-container{
    display: flex;
    justify-content: flex-start;
}

.button-container .hero-button{
    margin-right: 1.5rem;
    white-space: nowrap;
    width: 18.5rem;
}



/*Form*/
.underline{
    text-decoration: underline;
}


form{
    position: relative;
    background: #1a1a1a;
    color: #fff;
    border: .1rem solid #0FD9FF;
    width: 100%;
    min-width: 400px;
}

form .form-container{
    padding: 2rem 2rem;
}

form .form-container h5{
    text-align: center;
    letter-spacing: .1rem;
}

form .form-container .input-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.input-container-inner{
    display: flex;
    justify-content: space-between;
}

form .form-container .input-container .input-container-inner .inner-one-line{
    padding: 0 2rem 0 0;
    width: 50%;
}

form .form-container .input-container .input-container-inner .noPadRight{
    padding-right: 0;
}

form .form-container .input-container .input-container-inner .inner-one-line label, form .form-container .input-container .input-container-inner label{
    display: block;
    margin-bottom: 0;
    text-align: left;
    font-size: calc(1rem+ (18 - 16) * ((100vw - 600px) / (1920 - 600)));
}

form .form-container .input-container .input-container-inner .inner-one-line input{
    border: 1px solid #09E9FF;
    width: 100%;
    max-width: 400px;
    height: 2rem;
    padding-left: 1rem;
    margin-bottom: 1rem;;
}


form .form-container .button-container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: .5rem 0;
}

form .form-container .button-container .button-main{
    background: #0FD9FF;
    font-weight: bold;
    font-size: 1.5rem !important;
    color: #fff;
    width: 100%;
    transform: scale(1);
    border-radius: .4rem;
    letter-spacing: .2rem;
    
}

.thank-you-message{
    padding: 3rem;
    text-align: center;
}

@media screen and (min-width: 1920px){
    .hero .infobox-container .infobox-left h1{
        font-size: 3.6875rem;
    }
    
    .hero .infobox-container .infobox-left p{
        font-size: 1.125rem;
    }

    form .form-container .input-container .input-container-inner .inner-one-line label, form .form-container .input-container .input-container-inner label{
        font-size: 1.125rem;
    }

    form .form-container .button-container .button-main{
        font-size: 1.875rem;
    }
    
}

@media screen and (max-width: 1024px){
    .hero{
        height: 140vw;
        min-height: 200vw;
        max-height: 100vh;
        background: #000;
        background: url("../../images/Baron-Esports Championship Rings-COD-Halo Rings-T.jpg") #000;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .hero .infobox-container .infobox-left h1{
        padding: 2rem 0;
        text-align: center;
    }
    
    .hero .infobox-container{
        justify-content: flex-start;
        width: 80%;
        margin: auto;
        flex-direction: column;
        padding: 1rem 0;
        position: relative;
    }

    .hero .infobox-container .infobox-left{
        min-width: 100%;
        width: 80%;
        height: 50%;
        margin: 0;
        align-items: center;
        position: initial;
        padding-left: 0rem;
    }

    .hero .infobox-container .infobox-left p{
        text-align: center;
        padding: 1rem 3rem;
        margin: 0;
        width: 100%;
    }

    .button-container .hero-button{
        margin-right: 0;
    }


    form{
        padding: 2rem;
        width: 100%;
        min-width: 100%;
        /* position: absolute;
        bottom: 0; */
    }

}

 @media screen and (max-width: 600px){
    .hero{
        min-height: 1640px;
        background: url('../../images/Baron-Esports Championship Rings-COD-Halo Rings-mobile.jpg');
        background-size: cover;
        background-position: center;
     }
    .hero .infobox-container .infobox-left h1{
        font-size: 2rem;
    }
    
    form .form-container{
        padding: 2rem 0;
    }
    form .form-container .input-container .input-container-inner{
        flex-direction: column;
    }

    form .form-container .input-container .input-container-inner .inner-one-line{
       padding: 0;
        width: 100% !important;
        margin: .5rem 0;
    }

    form .form-container .button-container .button-main{
        font-size: 1rem !important;
    }
}

