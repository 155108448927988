.section-container{
    /* height: 840px; */
    height: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: #09abc4 url("../../images/Design-Your-Ring-esports-ring-Baron-Rings-Team-Rings.jpg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0rem 3rem;
    max-width: 1920px;
    margin: 0 auto;
}

.section-container h2{
    font-size:  2.2rem;
    /*font-size: 2.5vw; */
    font-size: calc(1.5rem + (48 - 25) *((100vw - 600px) / (1920 - 600)));
    letter-spacing: 0.06rem;
    padding: 0;
    font-weight: 500;
    color: #fff;
    line-height:  2.938rem;
    position: relative;
    white-space: nowrap;
}

.section-container p{
    color: #fff;
    padding-bottom: 2rem;
    font-size: calc(1rem + (18 - 16) * ((100vw - 600px) / (1920 - 600)));
}

.trademark-4{
    font-size: calc(1rem + (30 - 16) *((100vw - 600px) / (1920 - 600)));
    vertical-align: super;
}

@media screen and (min-width: 1920px){
    .section-container{
        /* height: 840px; */
    }

    .section-container h2{
        font-size:  3rem;
    }
    
    .section-container p{
        font-size: 1.125rem
    }
}

@media screen and (max-width: 1560px){
    .section-container h2{
        margin-bottom: 0;
    }

    .section-container p{
        padding-bottom: 1rem;
        line-height: 1.4;
    }
}

@media screen and (max-width: 1024px){
    /* .section-container{
        height: 1955px;
        background: #09abc4 url("../../images/Esports-design-your-ring-v1-992.jpg");
        background-size: auto;
        background-position: center;
        background-repeat:no-repeat ;
        padding-bottom: 2rem;
        display: none;
    }

    .section-container h2{
        font-size: 2.5rem;
        padding: 1rem 2rem;
        white-space: normal;
    }
    
    .section-container p{
        white-space: normal;
        line-height:  2.0635rem;
    } */
    /* .section-container{
        display: none;
    } */

    .section-container-mobile{
        height: auto;
        background: #09abc4;
    }
    
    .section-container-mobile img{
        width: 100%;;
    }

    .section-container-mobile h2{
        font-size:  3rem;
        /*font-size: 2.5vw; */
        font-size: calc(1.5rem + (48 - 25) *((100vw - 600px) / (1920 - 600)));
        letter-spacing: 0.06rem;
        padding: 2rem;
        font-weight: 500;
        color: #fff;
        line-height:  2.938rem;
        position: relative;
    }
    
    .section-container-mobile p{
        color: #fff;
        padding: 0 2rem 2rem;
        font-size: calc(1rem + (18 - 16) * ((100vw - 600px) / (1920 - 600)));
        margin-bottom: 0;
    }
    
}


@media screen and (max-width: 768px){
    .section-container{ 
        height: 200vw;
        background: url("../../images/Esports-design-your-ring-v1.jpg");
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        padding: 0rem 2rem 1rem; 
    } 

    .section-container h2, .section-container p{
        padding: 0rem 0 2rem;
    }
}

@media screen and (max-width: 600px){
    .section-container{
        /* height: 1150px; */
        background: url("../../images/Design Your Esports Championship Rings-M.jpg");
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        padding-bottom: 0;
    }
    .section-container-mobile h2{
        font-size: 1.5rem;
        line-height: 1.3;
        padding-bottom: 1rem;
        margin-bottom: 0;
    }

    .section-container-mobile p{
        line-height: 1;
        margin-bottom: 0;
        font-size: 1rem;
        line-height: 1.4;
    }

    .extra{
        display: none;
    }

}

@media screen and (max-width: 400px){
    .section-container{
        height: 956px;
        background: url("../../images/Esports-design-your-ring-v1.jpg");
        background-size: 100%;
        background-position: top center;
        background-repeat: no-repeat;
        padding-bottom: 0;
    }
}

@media screen and (max-width: 360px){
    .section-container{
        height: 918px;
    }
}

@media screen and (max-width: 360px){
    .section-container{
        height: 815px;
    }
}