*{
  box-sizing: border-box;
}

@font-face {
  font-family: "FontAwesome";
  src: url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-brands-400.eot"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-brands-400.woff2") format("woff2"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-brands-400.woff") format("woff"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-brands-400.ttf") format("truetype"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-brands-400.svg#fontawesome") format("svg");
  }
  
  @font-face {
  font-family: "FontAwesome";
  src: url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-solid-900.eot"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-solid-900.woff2") format("woff2"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-solid-900.woff") format("woff"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-solid-900.ttf") format("truetype"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-solid-900.svg#fontawesome") format("svg");
  }
  
  @font-face {
  font-family: "FontAwesome";
  src: url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-regular-400.eot"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-regular-400.woff2") format("woff2"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-regular-400.woff") format("woff"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-regular-400.ttf") format("truetype"),
      url("https://use.fontawesome.com/releases/v5.14.0/webfonts/fa-regular-400.svg#fontawesome") format("svg");
  unicode-range: U+F004-F005,U+F007,U+F017,U+F022,U+F024,U+F02E,U+F03E,U+F044,U+F057-F059,U+F06E,U+F070,U+F075,U+F07B-F07C,U+F080,U+F086,U+F089,U+F094,U+F09D,U+F0A0,U+F0A4-F0A7,U+F0C5,U+F0C7-F0C8,U+F0E0,U+F0EB,U+F0F3,U+F0F8,U+F0FE,U+F111,U+F118-F11A,U+F11C,U+F133,U+F144,U+F146,U+F14A,U+F14D-F14E,U+F150-F152,U+F15B-F15C,U+F164-F165,U+F185-F186,U+F191-F192,U+F1AD,U+F1C1-F1C9,U+F1CD,U+F1D8,U+F1E3,U+F1EA,U+F1F6,U+F1F9,U+F20A,U+F247-F249,U+F24D,U+F254-F25B,U+F25D,U+F267,U+F271-F274,U+F279,U+F28B,U+F28D,U+F2B5-F2B6,U+F2B9,U+F2BB,U+F2BD,U+F2C1-F2C2,U+F2D0,U+F2D2,U+F2DC,U+F2ED,U+F328,U+F358-F35B,U+F3A5,U+F3D1,U+F410,U+F4AD;
  }
.red{
  color: red;
  letter-spacing: 1px;
}
a{
  text-decoration: none ;
  text-underline: none;
}

a:hover{
  text-decoration: none;
  color: #fff;
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.App > div{
  background: #000;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.oswald{
  font-family: 'Oswald', sans-serif;
}

.roboto{
  font-family: 'Roboto', sans-serif;
}

.crimson{
  font-family: 'Crimson Text', serif;
}

.bold{
  font-weight: 800;
}

.button-main{
  cursor: pointer;
  color: #fff;
  background: #b2a37d;
  padding: 1rem;
  letter-spacing: .1rem;
  border: none;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  /* width: 216px; */
}

/* Bounce In */

.button-main:hover, .button-main:focus, .button-main:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  background: #AA1F9F !important;
}

.links{
  color: #000;
}

.links:hover{
  color:#b2a37d;
}

.copyright-links{
  color: #fff;
}
.copyright-links:hover{
  color:#b2a37d;
}

.password-page{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: #fff !important;
}

.password-page img{
  width: 300px;
}
.password-page div input{
  width: 400px;
  padding: 1rem;
  border: 2px solid #b2a47d;
}

.password-page div{
  display: flex;
}
.password-page div button{
  padding: 1rem;
  background: #b2a37d;
  border: 2px solid #b2a47d;
}

.password-page div button:hover{
  background: #000;
  color: #fff;
}


@media screen and (min-width: 577px) and (max-width: 768px){
h3, h4, blockquote{
  /* font-size: 1.75rem !important; */
}
}
@media screen and (max-width: 600px){
  .password-page div input{
    width: 95%;
    margin-bottom: 3rem;
  }

  .password-page div button{
    padding: 1rem;
    background: #b2a37d;
    border: 2px solid #b2a47d;
    width: 90%;
  }
  /* p{
    font-size: 0.9rem;
  } */
}
