.privacy-container{
    padding: 2rem 0rem;
    background: #fff;
    width: 100%;
}
.privacy-inner{
    max-width: 1240px;
    text-align: left;
    margin: auto;
}

.privacy-container h1{
    color: #b2a37d;
    font-family: 'oswald';
}

.privacy-container img{
    width: 125px;
    margin: auto;
    display: block;
}

.privacy-container div{
    padding: 1rem 0 2rem;
}

.privacy-container div h1, .privacy-container div h2{
    font-family: 'oswald', sans-serif;
}

.privacy-container div p, .privacy-container div ul li{
    font-family: 'Roboto', sans-serif;
}

.privacy-container div p a{
    color: #000;
}

.privacy-container div p a:hover{
    color: #b2a37d;
}

.privacy-contact p:nth-child(2){
    margin-bottom: 1rem;
}
.privacy-contact p{
    margin-bottom: 0;
}
.return{

    font-size: 1.2rem;
}
@media screen and (max-width: 576px){
    .privacy-container{
        padding: 2rem 1rem;
    }
    .privacy-container h1{
        font-size: 1.5rem;
    }
    .privacy-container div h2{
        font-size: 1.3rem;
    }  
}