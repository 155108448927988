.collection_container{
    height: 300px;
    /* display: flex;
    justify-content:flex-end;
    align-items: center; */
    background: #000;
    color: #fff;
    background: url("../../images/Baron Championship Rings-Esports Quote.jpg");
    background-size: cover;
    background-position: 30%;
    background-repeat: no-repeat;
    /* padding: 5rem 0; */
    max-width: 1920px;
    margin: 0 auto;
}

.collection_container_inner{
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.collection_grid{
    position: absolute;
    width: 100%;
    max-width: 1700px;
    margin: 0 auto;
}
.collection_container_inner h3, .collection_container_inner blockquote, .collection_container_inner cite{
    font-size: calc(1rem + (26 - 16) * ((100vw - 600px) / (1800 - 600)));
    letter-spacing: .2rem;
    line-height: 1.7;
    padding: 0rem 1rem;
    position: relative;
    color: #09abc4;
}


.collection_trademark{
    font-size: calc(.7rem + (14 - 12) * ((100vw - 600px) / (1800 - 600)));
    vertical-align: super;
}

.switch-box{
    margin: 0 auto;
    display: flex;
    width: 50px;
    justify-content: space-around;
    align-self: flex-end;
    position: absolute;
    bottom: 1.3rem;
    left: 50%;
    padding-bottom: 2rem;
}

.slide-one, .slide-two{
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    cursor: pointer;
}

.active{
    background: #09abc4;
}

.secondary{
    background: #AA1F9F;
}

@media screen and (min-width: 1920px){
    .collection-container{
        height: 300px;
    }

    .collection-container .container-inner blockquote{
        font-size: 1.375rem;
    }
    
    cite{
        font-size: 1.375rem;
    }
}


@media screen and (max-width: 1024px){
    .collection-container{
        /* height: 1300px;
        background: url("../../images/Testimonial--M-v1-992.jpg");
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 5rem; */
        display: none;
    }

    .collection-container-mobile{
        background: #09297e;
    }


    .collection-container-mobile .collection-inner-mobile img{
        width: 100%;
    }

    .collection-container-mobile .collection-inner-mobile .content-grid{
        padding: 2rem;
    }

    .collection-container-mobile .collection-inner-mobile .content-grid blockquote{
        font-size: calc(1rem + (26 - 16) * ((100vw - 600px) / (1800 - 600)));
        letter-spacing: .2rem;
        padding: 0rem 0rem;
        position: relative;
        color: #fff;;
    }

    cite{
        color: #fff;
    }

    .trademark, .trademark-2{
        font-size: calc(.6rem + (14 - 10) * ((100vw - 600px) / (1800 - 600)));
        vertical-align: super;
    }

}

@media screen and (max-width: 768px){
    .collection-container{
        padding: 3rem 1rem;
    }

    .collection-container blockquote{
        width: 100%;
        padding: 1rem;
    }


}

@media screen and (max-width: 600px){
    .collection-container{
        height: 900px;
        /* display: none; */
        background: url("../../images/Testimonial--M-v1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-bottom: 0;
        padding: 5rem 2rem 2rem;
    }



    .collection-container blockquote{
       font-size: 1rem;
       padding: 0;
    }

    cite{
        padding-bottom: 2rem;
    }

    .switch-box{
        display: none;
    }
}






