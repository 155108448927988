.no_margin_bottom{
    margin-bottom: 0 !important;
  }

  
.sectionThree_container{
    height: 950px;
    /* height:65vw; */
    /* min-width: 500px; */
    background: url('../../images/Baron Championship Rings-Esports Collection-Esports Package-Ring Designs.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 4rem 4rem;
    max-width: 1920px;
    margin: 0 auto;
}


.sectionThree_container_inner h3{
    font-size: 2.2rem;
    color: #fff;
    padding: 0rem 0 2rem;
    font-weight: 500;
    letter-spacing: 0.06rem;
    line-height: 3.125rem;
    position: relative;
    max-width: 1600px;
    margin: 0 auto;
}

.section_three_columns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin: auto;
    flex-wrap: wrap;
}

.section_three_columns_inner{
    display: flex;
    justify-content: center;
}
.sectionThree_container_inner p{
    color: #fff;
    /* padding-bottom: 5rem; */
    font-size: 1.063rem;
    /* width: 70%; */
    margin: 0 auto;
    /* max-width: 1600px; */
    text-align: left;
}

.sectionThree_container_inner a{
    margin: 5rem 0;
    background: #AA1F9F;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem !important;
    letter-spacing: .2rem;
    line-height:  1.875rem;
    padding: 2% 3%;
    border-radius: .4rem;
    display: inline;
}

.section_three_button_container{
    padding: 4rem 0;
}

.sectionThree_container_inner .section_three_button_container a:hover{
    background: #09E9FF !important;
    color: #02043d !important;
    transform: scale(1);
    
}

@media screen and (min-width: 1920px){
    .sectionThree_container_inner h3{
        font-size: 2.375rem;
    }
    
    .sectionThree_container_inner p{
        font-size: 1.125rem;
    }
    
    .sectionThree_container_inner a{
        font-size: 1.875rem;
    }
}

@media screen and (max-width: 1024px){
    .sectionThree_container{
        height: 130vw;
        background: url('../../images/Esports-Collection-Section-v1-992.jpg');
        background-size: cover;
        background-position: bottom;
    }

    .sectionThree_container_inner a{
        width: 78%;
        font-weight: bold;
        font-size: 1.875rem;
    }
}

@media screen and (max-width: 768px){
    .sectionThree_container{
        height: 150vw;
        background: url('../../images/Esports-Collection-Section-v1-768.jpg');
        background-size: cover;
        background-position: bottom;
        padding: 2rem 2rem;
    }

    .sectionThree_container_inner a{
        /* width: 100%; */
        font-weight: bold;
        font-size: 1.575rem;
    }

    .section_three_columns p{
        padding-bottom: 1rem !important;
    }
}


@media screen and (max-width: 700px){
    .sectionThree_container{
        height: 170vw;
    }
}
@media screen and (max-width: 600px){
    .sectionThree_container{
        height: 270vw;
        background: url('../../images/Esports-Collection-Section-v1b-500.jpg');
        background-size: cover;
        background-position:bottom;
        padding: 5rem 2rem;
    }

    .section_three_columns{
        margin: 0;
    }

    .section_three_button_container{
        padding: 0;
        display: flex;
        justify-content: flex-start;
        width: 90%;
    }

    .sectionThree_container_inner h3{
        font-size: 1.8rem;
        line-height: 1.4;
        padding-bottom: 2rem;
        text-align: left;
    }

    .sectionThree_container_inner p{
        padding-bottom: 3rem;
        width: 100%;
    }


    .section_three_button_container a{
        font-weight: bold;
        white-space: normal;
        line-height: 1.875rem;
        margin: 1rem 0;
        font-size: 1rem !important;
    }

    .sectionThree_trademark_lg{
        top:2.8rem;
    }
}

@media screen and (max-width: 520px){
    .sectionThree_container{
        height: 360vw;
    }
}