.no_margin{
    margin: 0;
  }

  .no_padding{
    padding: 0;
  }

.info-container{
    background:#efefef;
    padding: 5rem .5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
}

.info-inner{
    display: flex;
    flex-wrap: wrap-reverse;
    max-width: 1600px;
    margin: 0 auto;
}

.info-inner .gaming_trophy{
    width: 45%;
    position: absolute;
    left: 0;
    top: 2rem;
    max-width: 800px;
    min-width: 400px;
}
.info-inner h2{
    text-align: left;
}

.info-inner-left{
    width: 50%;
    margin-left: auto;
    text-align: left;
}

.info-inner-left p{
    font-size: 1.063rem;
}

.info-inner-bottom div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 1rem 0 0;
}

.info-inner-bottom div img{
    margin-right: 1rem;
}

@media screen and (max-width: 992px){
    .info-container{
        padding: 2rem;
    }

    .inner-info{
        flex-direction: column;
        justify-content: center;
    }

    .info-inner .gaming_trophy{
        width: 100%;
        position: relative;
        min-width: 100%;
        margin: auto;
        margin-bottom: 2rem;
    }

    .info-inner-left{
        width: 100%;
        margin-left: 0;
    }
}


@media screen and (max-width: 600px){
    .info-inner-bottom{
        max-width: 90%;
        margin-left: 0;
    }
}





