.design_container{
    height: auto;
    padding:0;
    background: #09abc4;
    display: flex;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: auto;
}

.design_inner_left, .design_inner_right{
    width: 50%;
}

.design_inner_left{
    text-align: left;
    min-width: 400px;
}

.design_inner_left h4{
    text-align: left;
    font-size: 2.2rem;
}

.design_inner_left p{
    color: #fff;
}

.design_inner_left a{
    background: #AA1F9F;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem !important;
    letter-spacing: .2rem;
    line-height:  1.875rem;
    padding: 2% 3%;
    border-radius: .4rem;
    margin: 2rem 0;
}

.design_inner_left a:hover{
    background: #09E9FF !important;
    color: #02043d !important;
    transform: scale(1);
}

@media screen and (max-width: 992px){
    .design_container{
        padding: 0rem 2rem;
    }

    .design_inner_left, .design_inner_right{
        width: 100%;
    }

    .design_inner_left a{
        font-size: 1rem !important;
        text-align: left;
    }
}